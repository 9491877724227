export default {
  defaultGuests: 2,
  defaultNights: 1,
  initialNights: 3,
  maxGuests: 80,
  maxNights: 14,
  // TODO HWPWA-2043 (https://jira.corp.hostelworld.com/browse/HWPWA-2043)
  extendedMaxNights: 30,
  propertiesPerPage: 30,
  imagesPerProperty: 30,
  limitPropertiesForSocialCues: 30,

  sort: [
    { icon: 'price-low', label: 't_LOWESTPRICE', value: 'lowestprice' },
    { icon: 'price-high', label: 't_HIGHESTPRICE', value: 'highestprice' },
    { icon: 'star-line', label: 't_BESTRATED', value: 'rating' },
    { icon: 'map-pin-double', label: 't_DISTANCEFROMCITYCENTRE', value: 'distance' },
    { icon: 'promotion', label: 't_PROMOTIONS', value: 'promotions' },
  ],
};
