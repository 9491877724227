<template>
  <ul class="facility-sections">
    <li v-for="facilitySection in items" :key="facilitySection.id">
      <h3>
        <svg-icon :name="sectionIcons[facilitySection.name]" class="facility-icon" />
        <span>{{ $t('t_' + facilitySection.name) }}</span>
      </h3>
      <ul class="facilities">
        <li v-for="facility in facilitySection.facilities" :key="facility.id">
          {{ $t('t_' + facility.name) }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Facilities',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sectionIcons: {
        FACILITYCATEGORYFREE: 'gift',
        FACILITYCATEGORYGENERAL: 'clipboard-check',
        FACILITYCATEGORYSERVICES: 'hand-heart',
        FACILITYCATEGORYFOODANDDRINK: 'utensils',
        FACILITYCATEGORYENTERTAINMENT: 'gamepad',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  font-size: $wds-font-size-body-3;

  &.facility-sections {
    padding: 0 0 $wds-spacing-m 0;

    & > li {
      color: $wds-color-blue;
      padding-bottom: $wds-spacing-xl;

      h3 {
        display: flex;
        align-items: center;
        gap: $wds-spacing-s;
        border-bottom: wds-rem(1px) dotted $wds-color-ink-light;
        font-size: $wds-font-size-body-2;
        padding: 0 0 $wds-spacing-m 0;

        .facility-icon {
          fill: $wds-color-blue;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &.facilities {
    color: $wds-color-ink-dark;
    padding: 0 0 $wds-spacing-m 0;
    width: 100%;
    display: inline-block;

    li {
      padding: $wds-spacing-m 0 0 0;
      line-height: 1.43;
      width: 50%;
      display: inline-block;
      @include tablet {
        width: 33%;
      }

      @include desktop {
        width: 25%;
      }
    }
  }
}
</style>
