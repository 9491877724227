<template>
  <div>
    <header>
      <rwd-header-nav />

      <rwd-hero-image :images="getPropertyMainImage" />
      <div
        class="hero-clickable-area"
        @click="openImageGalleryModal(0, 'header')"
        @keypress.enter="openImageGalleryModal(0, 'header')"
        role="button"
        tabindex="0"
      >
        <div class="rwd-wrapper property-headline-section">
          <rwd-headline
            class="property-headline-wrapper"
            :property-info="propertyInfo"
            :crumbs="crumbs"
            :badges="getPropertyBadgesData"
            @sustainability-badge-clicked="handleSustainabilityBadgeClick"
          />
        </div>
      </div>
    </header>

    <main class="container">
      <section v-if="shouldShowNoAvailabilityMessage" class="property-no-availability-message">
        <div class="rwd-wrapper">
          <hw-no-availability-message :location="propertyInfo.name" />
        </div>
      </section>
      <div v-if="isBelowMediumScreen" class="check-availability-button">
        <rwd-button
          :color="showSearchForm ? 'dark' : 'primary'"
          @click="showSearchForm = !showSearchForm"
          :full-width="true"
        >
          {{ showSearchForm ? $t('t_CLOSE') : $t('t_CHECKAVAILABILITY') }}
        </rwd-button>
      </div>
      <rwd-search v-show="showSearchForm || !isBelowMediumScreen" :location="propertyInfo.searchLocation" />
      <rwd-free-cancellation v-if="hasFreeCancellation" :daysInAdvance="getFreeCancellationDay" />

      <hw-social-banner v-if="experiment_social_banner" />

      <rwd-info-bar @item-click="infoBarItemClicked" />

      <div class="property-overview-section">
        <div class="rwd-wrapper property-overview-container">
          <div class="overview-rating">
            <a
              href="#"
              @click.prevent="openReviewsSideModal"
              @keypress.space.prevent="openReviewsSideModal"
              :aria-label="$t('t_VIEWALLREVIEWS')"
            >
              <rwd-rating-summary v-bind="getPropertyRatingSummaryData" />
            </a>

            <rwd-rating-factors v-if="hasValidRatingFactors" v-bind="getPropertyRatingFactorsData" />
            <div class="rating-old-reviews-message" v-else>{{ $t('t_ONLYPROPERTIESRECEIVERATING') }}</div>

            <div class="rating-read-all-reviews">
              <a href="#" @click.prevent="openReviewsSideModal" @keypress.space.prevent="openReviewsSideModal">{{
                $t('t_VIEWALLREVIEWS')
              }}</a>
            </div>
          </div>

          <rwd-recommended-property v-if="propertyInfo.isRecommended" />

          <div class="overview-descriptions">
            <rwd-property-overview v-bind="getPropertyOverviewData" @house-rules-link="openHouseRules" />
          </div>
        </div>
      </div>

      <div class="rwd-wrapper property-image-gallery">
        <rwd-thumbnail-list
          :gallery="propertyInfo.imageGallery"
          :width="imageSizes.propertyList.thumb.width"
          :height="imageSizes.propertyList.thumb.height"
          @show-ms-gallery="openImageGalleryModal"
        />
      </div>

      <rwd-property-rating-section
        v-if="propertyInfo.rating.score"
        class="rwd-wrapper"
        :summary-info="getPropertyRatingSummaryData"
        :breakdown-info="propertyInfo.rating.breakdown"
        @rating-summary-click="openReviewsSideModal"
      />

      <div ref="property-availability" class="property-availability-section">
        <div class="rwd-wrapper property-availability-container">
          <h2>{{ $t('t_CHECKAVAILABILITY') }}</h2>
          <rwd-availability
            :show-location-field="false"
            :location="{
              type: 'property',
              id: propertyInfo.id,
              city: propertyInfo.urlFriendlyCity,
              hostelName: propertyInfo.urlFriendlyName,
            }"
          />
        </div>
      </div>

      <section v-if="hasSustainability" class="property-sustainability-section rwd-wrapper" ref="sustainability">
        <wds-icon class="sustainability-icon" v-bind="getSustainabilityIcon" />
        <h2 class="sustainability-title">{{ propertyInfo.sustainability.title }}</h2>
        <div class="sustainability-description">{{ propertyInfo.sustainability.description }}</div>
        <a
          class="sustainability-learn-more"
          href="https://global.hostelworld.com/sustainability"
          @click="handleSustainabilityLearnMore"
          >{{ $t('t_LEARNMORE') }}</a
        >
      </section>

      <section class="property-map-section rwd-wrapper" ref="map">
        <h2>{{ $t('t_LOCATION') }}</h2>
        <client-only>
          <div class="property-map-container">
            <mapbox-map tag="div" v-bind="getMapData" />
          </div>
        </client-only>
      </section>

      <div v-if="hasValidLatestReviews" class="property-lastest-reviews-section">
        <rwd-property-latest-reviews-section
          class="rwd-wrapper"
          :latest-reviews-list="propertyInfo.reviews"
          @read-all-reviews-click="this.openReviewsSideModal"
        />
      </div>

      <div class="property-facilities-section" ref="facilities">
        <div class="rwd-wrapper property-facilities-container">
          <h2>{{ $t('t_FACILITIES') }}</h2>
          <rwd-property-facilities :items="propertyInfo.facilities" />
        </div>
      </div>

      <div v-if="propertyHasAwardsOrNominations" class="property-awards-section">
        <div class="rwd-wrapper property-awards-container">
          <h2>{{ $t('t_AWARDS') }}</h2>
          <rwd-property-awards :awards="propertyInfo.hoscars.awards" :nominations="propertyInfo.hoscars.nominations" />
        </div>
      </div>

      <div v-if="propertyInfo.hostelSays" class="property-says-section">
        <div class="rwd-wrapper property-says-container">
          <h2>{{ $t('t_PROPNAMESAYS', { PROPERTYNAME: propertyInfo.name }) }}</h2>
          <div v-html="propertyInfo.hostelSays" />
        </div>
      </div>

      <more-properties-in-city
        tag="section"
        class="more-properties-in-city-section rwd-wrapper"
        :city-name="propertyInfo.city"
        :property-list="getMorePropertiesInCityListing"
        :property-type-obj="propertyTypeObj"
        @more-properties-card-click="handleMorePropertiesCardClick"
      />

      <div class="view-all-city-properties-section">
        <div class="rwd-wrapper view-all-city-properties-container">
          <rwd-button @click="navigateToCityFab">
            {{
              $t('t_VIEWALLCITYPROPTYPES', {
                CITY: propertyInfo.city,
                PROPTYPES: propertyInfo.propertyTypePlural,
              })
            }}
          </rwd-button>
        </div>
      </div>
    </main>

    <Footer />

    <client-only>
      <rwd-house-rules v-if="modals.houseRules" v-bind="getPropertyHouseRulesData" @close="closeHouseRules" />
      <rwd-reviews v-if="modals.reviews" v-bind="getPropertyReviewsHistoryData" @close="closeReviewsSideModal" />
      <rwd-image-gallery
        v-if="modals.imageGallery && propertyInfo.imageGallery.length"
        ref="gallery"
        :begin-image="imageGalleryBegin"
        :images="propertyInfo.imageGallery"
        :property-name="propertyInfo.name"
        :property-type="propertyInfo.propertyTypeSingular.toUpperCase()"
        @close-gallery="closeImageGalleryModal"
      ></rwd-image-gallery>
    </client-only>

    <client-only>
      <CookieBar />
    </client-only>
  </div>
</template>

<script>
import { DEFAULT_LANGUAGE } from '~/config/languages';
import Footer from '~/components/common/Footer';
import BreadCrumbs from '~/components/common/BreadCrumbs';
import RwdHeaderNav from '~/components/hwrwd/common/Header/HeaderNav';
import RwdHeroImage from '~/components/hwrwd/common/Header/HeroImage';
import RwdHeaderTitle from '~/components/hwrwd/common/Header/HeaderTitle';
import RwdHeadline from '~/components/hwrwd/property/Headline';
import RwdInfoBar from '~/components/hwrwd/property/InfoBar';
import RwdButton from '~/components/hwrwd/inputs/Button';
import RwdCoreButton from '~/components/hwrwd/core/Button';
import RwdSearch from '~/components/hwrwd/common/Search';
import RwdFreeCancellation from '~/components/hwrwd/common/FreeCancellation';
import RwdAvailability from '~/components/hwrwd/common/SearchForm/index';
import RwdRatingSummary from '~/components/hwrwd/common/Ratings/RatingSummary';
import RwdRatingFactors from '~/components/hwrwd/common/Ratings/RatingFactors';
import RwdRatingBreakdown from '~/components/hwrwd/common/Ratings/RatingBreakdown';
import RwdRecommendedProperty from '~/components/hwrwd/property/RecommendedProperty';
import RwdPropertyOverview from '~/components/hwrwd/property/PropertyOverview';
import RwdThumbnailList from '~/components/hwrwd/property/ThumbnailList';
import RwdPropertyRatingSection from '~/components/hwrwd/property/RatingSection';
import RwdPropertyLatestReviewsSection from '~/components/hwrwd/property/LatestReviewsSection';
import RwdPropertyFacilities from '~/components/hwrwd/property/Facilities';
import RwdPropertyAwards from '~/components/hwrwd/property/Awards';
import HwSocialBanner from '~/components/common/HwSocialBanner';
import HwNoAvailabilityMessage from '~/components/common/HwNoAvailabilityMessage';
import MorePropertiesInCity from '~/components/property/MorePropertiesInCity';
import { getHttpErrors, internalServerErrorPage, notFoundErrorPage } from '~/lib/http';
import { setHref } from '~/lib/dom';
import TranscodeInterpolate from '~/models/TranscodeInterpolate';
import ogTags from '~/lib/ogTags';
import twitterTags from '~/lib/twitterTags';
import { socialMediaTags } from '~/lib/socialMediaMetaTags';
import RatingMixin from '~/components/hwrwd/common/Ratings/mixin';
import mapboxConfig from '~/config/mapbox';
import { mapActions, mapGetters } from 'vuex';
import mq from '~/mixins/mq';
import imageSizes from '~/config/hwrwd/images';
import { TRACKING_EMPTY } from '~/tracking/variables';
import { WdsIcon } from '@wds/components';

const entryPageName = 'PROPERTY';
const trackingPageType = 'staticMicrosite';
const AUTO_GENERATED_TEXT_STRING = '(Auto-translated from original language)';

export default {
  name: 'PropertyPage',

  middleware: 'redirect',

  layout: 'hwrwd',

  mixins: [RatingMixin, mq],

  components: {
    WdsIcon,
    MorePropertiesInCity,
    Footer,
    BreadCrumbs,
    RwdHeaderNav,
    RwdHeroImage,
    RwdHeaderTitle,
    RwdHeadline,
    RwdInfoBar,
    RwdSearch,
    RwdFreeCancellation,
    RwdAvailability,
    RwdButton,
    RwdCoreButton,
    RwdRatingSummary,
    RwdRatingFactors,
    RwdRatingBreakdown,
    RwdRecommendedProperty,
    RwdPropertyOverview,
    RwdThumbnailList,
    RwdPropertyRatingSection,
    RwdPropertyLatestReviewsSection,
    RwdPropertyFacilities,
    RwdPropertyAwards,
    HwSocialBanner,
    HwNoAvailabilityMessage,
    MapboxMap: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/Mapbox/MapboxMap'),
    RwdHouseRules: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/hwrwd/property/HouseRules'),
    RwdReviews: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/hwrwd/property/Reviews'),
    RwdImageGallery: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/hwrwd/property/ImageGallery'),
    CookieBar: /* istanbul ignore next */ () => import(/* webpackMode: "lazy" */ '~/components/common/CookieBar/index'),
  },

  async asyncData({ req, res, app, store, params, error, query }) {
    store.commit('session/setEntryPoint', entryPageName);
    const urlParams = params || app.router.history.current.params;
    const queryParams = query || app.router.history.current.query;
    const langUrl = store.state.session.language.tag || DEFAULT_LANGUAGE.tag;
    app.i18n.locale = langUrl;
    const userCurrency = store.state.session.currency.code;

    let propertyIdUrl = parseInt(params.propertyId) || null;
    let propertyInfo;

    const propertyTypeObj = app.$routeManager.validatePropertyTypeParam(urlParams.propertyType, store);
    const propertyTypeUrl = propertyTypeObj.slug;
    const propertyTypeKey = propertyTypeObj.key;

    try {
      propertyInfo = await app.$routeManager.validatePropertyParam(langUrl, propertyIdUrl, app, store, req, res);
    } catch (err) {
      if (err) {
        console.error(err);
        const worldWideUrl = app.$url.getWorldPageUrl({
          propertyTypeSlug: propertyTypeObj.slug,
        });
        app.context.redirect(worldWideUrl);
        return;
      }
    }

    const urlData = app.$routeManager.getUrlData(
      propertyTypeUrl,
      null,
      null,
      null,
      null,
      propertyInfo.id,
      propertyInfo.urlFriendlyName
    );

    if (!propertyInfo || propertyInfo.id === null) {
      return error(notFoundErrorPage('Property not found.'));
    }

    // @TODO: STATICPWA-901 Remove after experiment is done
    const {
      ppc_redirect_to_pwa_enabled: experiment_ppc_redirect,
      static_socialBanner: experiment_social_banner,
      static_ignoreAvailability: experiment_no_availability_banner,
      web_sustainabilityBadges,
    } = store.$optimizely.isFeatureListEnabled([
      'ppc_redirect_to_pwa_enabled',
      'static_socialBanner',
      'static_ignoreAvailability',
      'web_sustainabilityBadges',
    ]);

    const sourcePPCParam =
      queryParams?.source && Array.isArray(queryParams?.source) ? queryParams?.source[0] : queryParams?.source;
    const isPPCTraffic = (sourcePPCParam && sourcePPCParam?.toLowerCase()?.includes('ppc_gooads_nonbrand')) || false;
    const is_experiment_ppc_redirect_enabled = !experiment_ppc_redirect && isPPCTraffic;

    if (experiment_ppc_redirect && isPPCTraffic) {
      const locationDataPPC = {
        propertyTypeSlug: propertyInfo.propertyTypeSlug,
        urlFriendlyName: propertyInfo.location.city.urlFriendlyName,
      };

      const propertyDataPPC = {
        id: propertyInfo.id,
        urlFriendlyName: propertyInfo.urlFriendlyName,
      };

      const addDays = store.$optimizely.getFeatureVariableInteger('ppc_redirect_to_pwa_enabled', 'numberofadddays');

      const queryPPC = {
        ...queryParams,
        noFeatured: '1',
        pd: '1',
        addDays: addDays || 14,
        origin: 'microsite',
      };

      const redirectPpcToPwaUrl = app.$url.getPwaPropertyDynamicUrl(locationDataPPC, propertyDataPPC, queryPPC);
      app.context.redirect(redirectPpcToPwaUrl);
    }

    await app.$routeManager.redirectRoute(app, store, urlData, query);

    const fullPropertyPath = `${propertyTypeUrl}/p/${propertyInfo.id}/${propertyInfo.urlFriendlyName}/`;

    propertyInfo.fullPath = `${fullPropertyPath}`;
    propertyInfo.fullUrl = `${app.$env.STATIC_URL}${fullPropertyPath}`;
    propertyInfo.canonicalUrl = `${app.$env['BASE_URL_PROTOCOL']}://${app.$env[store.state.session.language.baseUrl]}${
      propertyInfo.fullUrl
    }`;

    propertyInfo.propertyTypeSingular = app.i18n.t(propertyTypeObj.transcode_s);
    propertyInfo.propertyTypePlural = app.i18n.t(propertyTypeObj.transcode);
    propertyInfo.propertyTypeKey = propertyTypeKey;
    propertyInfo.propertyTypeSlug = propertyTypeUrl;
    propertyInfo.schema = propertyTypeObj?.schema;

    propertyInfo.searchLocation = {
      id: propertyInfo.id,
      type: 'property',
      city: propertyInfo.urlFriendlyCity,
      hostelName: propertyInfo.urlFriendlyName,
      label: `${propertyInfo.name}, ${propertyInfo.city}, ${propertyInfo.country}`,
    };

    const topServices = await app.$api.getSpApi().getServices('top');
    const topPropertiesInCity = await topServices.getTopPropertiesByCityId(
      langUrl,
      userCurrency,
      propertyTypeKey,
      propertyInfo.cityId
    );

    // More properties in the same city as the current property
    const morePropertiesInCity = topPropertiesInCity?.filter((c) => c.id !== propertyInfo.id)?.slice(0, 6) || [];

    propertyInfo.seoImage = propertyInfo?.mainImage?.desktop || propertyInfo?.images?.[0]?.desktop;

    const seoServices = await app.$api.getSpApi().getServices('seo');

    let seoData = seoServices.getPropertyTags(langUrl, propertyInfo.id);

    seoData = await seoData;

    if (seoData && seoData?.title) {
      seoData.ogTitle = seoData.ogTitle || seoData.title;
      seoData.ogDescription =
        (seoData.ogDescription || seoData.description).replace(/\r?\n|\r/g, '') ||
        app.i18n.t('t_DEFAULTSEODESCRIPTION');
      seoData.ogImage = propertyInfo.seoImage;
      seoData.ogUrl = seoData.canonical || propertyInfo.canonicalUrl;
      const tradReplacements = {
        PROPERTYNAME: propertyInfo.name,
        CITYNAME: propertyInfo.city,
        CITY: propertyInfo.city,
        CHOSENCITY: propertyInfo.city,
        CHOSENCOUNTRY: propertyInfo.country,
        REVIEWSCOUNT: propertyInfo.rating.totalNumReviews || '',
        COUNTRYNAME: propertyInfo.country,
        COUNTRY: propertyInfo.country,
        PROPERTYTYPE: propertyInfo.propertyTypeSingular,
        PROPERTYTYPES: propertyInfo.propertyTypePlural,
        LOCATIONNAME: propertyInfo.name,
        YEAR: new Date().getFullYear(),
      };
      const transcodeInterpolate = new TranscodeInterpolate();
      const propertiesToReplace = ['title', 'description', 'copy', 'ogTitle', 'ogDescription', 'heading', 'keywords'];
      propertiesToReplace.forEach((property) =>
        seoData[property]
          ? (seoData[property] = transcodeInterpolate.interpolate(seoData[property], tradReplacements))
          : (seoData[property] = '')
      );
    }

    const crumbs = [
      {
        label: app.i18n.t('t_A11YBCTITLEPROPERTYTYPE', {
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        title: propertyInfo.propertyTypePlural,
        link: app.$url.getWorldPageUrl(propertyInfo),
      },
      {
        label: app.i18n.t('t_A11YBCTITLELOCATION', {
          LOCATIONNAME: propertyInfo.continent,
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        title: app.i18n.t('t_A11YBCTEXTLOCATION', {
          LOCATIONNAME: propertyInfo.continent,
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        link: app.$url.getContinentPageUrl(propertyInfo),
      },
      {
        label: app.i18n.t('t_A11YBCTITLELOCATION', {
          LOCATIONNAME: propertyInfo.country,
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        title: app.i18n.t('t_A11YBCTEXTLOCATION', {
          LOCATIONNAME: propertyInfo.country,
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        link: app.$url.getCountryPageUrl(propertyInfo),
      },
      {
        label: app.i18n.t('t_A11YBCTITLELOCATION', {
          LOCATIONNAME: propertyInfo.city,
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        title: app.i18n.t('t_A11YBCTEXTLOCATION', {
          LOCATIONNAME: propertyInfo.city,
          PROPERTYTYPE: propertyInfo.propertyTypePlural,
        }),
        link: app.$url.getCityUrl(propertyInfo),
      },
      {
        title: propertyInfo.name,
        link: app.$url.getPropertyUrl(propertyInfo, propertyInfo, query),
      },
    ];

    let ogMetaTags = [];
    let twitterMetaTags = [];
    let linkTags = app.$linkTags.getLinkTag();

    if (seoData) {
      const pageOgTags = ogTags(seoData.ogTitle, seoData.ogImage, seoData.ogUrl, seoData.ogDescription, 'property');
      const pageTwitterTags = twitterTags(
        'summary_large_image',
        seoData.ogTitle,
        seoData.ogImage,
        seoData.ogUrl,
        seoData.ogDescription
      );
      ogMetaTags = socialMediaTags(pageOgTags);
      twitterMetaTags = socialMediaTags(pageTwitterTags);

      if (propertyInfo) {
        linkTags = app.$linkTags.getCanonicalTag(linkTags, propertyInfo.canonicalUrl);
        const linkArgs = [
          linkTags,
          app.$url.getHrefLangUrlforProperty,
          propertyInfo?.locationTranslations,
          propertyInfo.propertyTypeKey,
          propertyInfo.propertyTypePlural,
          propertyInfo.id,
        ];
        linkTags = app.$linkTags.getAlternateTagForProperty(...linkArgs);
        linkTags = app.$linkTags.getDefaultAlternateTagForProperty(...linkArgs);

        store.dispatch('route/setAllRoutesByLang', linkTags.getLinksByLang());
      }
    }

    return {
      crumbs,
      propertyInfo,
      seoData,
      propertyTypeObj,
      ogMetaTags,
      twitterMetaTags,
      linkTags,
      morePropertiesInCity,
      experiment_social_banner,
      experiment_no_availability_banner,
      is_experiment_ppc_redirect_enabled,
      web_sustainabilityBadges,
    };
  },

  head() {
    if (this.seoData) {
      this.$structuredMarkup.addDefault();

      if (this.propertyInfo) {
        this.addPropertySchema();
      }

      return {
        title: this.seoData.title,
        meta: [
          { hid: 'content-language', name: 'content-language', content: this.$store.state.session.language.tag },
          { hid: 'description', name: 'description', content: this.seoData.description },
          { hid: 'keywords', name: 'keywords', content: this.seoData.keywords },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index, follow, max-image-preview:large',
          },
          ...this.ogMetaTags,
          ...this.twitterMetaTags,
        ],
        link: this.linkTags.data,
        script: [
          {
            hid: 'ldjson-schema',
            innerHTML: JSON.stringify(this.$structuredMarkup.getFullSchema()),
            type: 'application/ld+json',
          },
        ],
        __dangerouslyDisableSanitizersByTagID: {
          'ldjson-schema': ['innerHTML'],
        },
      };
    }
  },

  data() {
    return {
      showSearchForm: false,
      currentYear: new Date().getFullYear(),
      imageGalleryBegin: 0,
      imageSizes,
      modals: {
        houseRules: false,
        reviews: false,
        imageGallery: false,
      },
      tracking: null,
    };
  },

  computed: {
    getMorePropertiesInCityListing() {
      return this.morePropertiesInCity || [];
    },

    getMapData() {
      const propertyLongitude = this.propertyInfo?.location?.geoCoordinates?.longitude || 0;
      const propertyLatitude = this.propertyInfo?.location?.geoCoordinates?.latitude || 0;

      return {
        mapConfig: {
          scrollZoom: false,
          center: [propertyLongitude, propertyLatitude],
          zoom: 13,
        },
        markerList: [
          {
            latitude: propertyLatitude,
            longitude: propertyLongitude,
            className: 'hw-map-property-pin',
          },
        ],
        propertyTypeObj: this.propertyTypeObj,
        autoFitBounds: false,
        applyClusters: false,
      };
    },

    getPropertyMainImage() {
      return this.propertyInfo.mainImage || this.propertyInfo.images[0] || {};
    },

    hasPropertyImages() {
      return this.propertyInfo?.imageGallery?.length || 0;
    },

    getPropertyOverviewData() {
      return {
        hostelworldSays: {
          title: this.$t('t_TITLEHOSTELWORLDSAYS'),
          text: this.propertyInfo.hwSays,
        },
        mainDescription: {
          title: this.$t('t_PROPERTYDESCRIPTION'),
          text: this.propertyInfo.mainDescription,
        },
      };
    },

    getPropertyRatingSummaryData() {
      return {
        rating: {
          isNew: this.propertyInfo.isNew,
          total: this.propertyInfo.rating.score,
          scoreMessage: this.propertyInfo.rating.score,
          color: 'orange',
          totalReviews: this.propertyInfo.rating.totalNumReviews,
        },
        size: 'big',
        showTotal: true,
        showKeyword: true,
      };
    },

    hasValidRatingFactors() {
      return this.propertyInfo?.rating?.score;
    },

    getPropertyRatingFactorsData() {
      return {
        ratingBreakdown: this.propertyInfo.rating.breakdown,
        isNew: this.propertyInfo.isNew,
      };
    },

    hasValidLatestReviews() {
      return this.propertyInfo?.rating?.score && this.propertyInfo?.reviews?.length;
    },

    getPropertyBadgesData() {
      const hasAwardsThisYear =
        this.propertyInfo?.hoscars?.awards?.length > 0 &&
        this.propertyInfo?.hoscars?.awards[0]?.year === this.currentYear;

      return {
        sanitization: this.hasFacility('SANITISATIONBADGE', 'FACILITYCATEGORYGENERAL'),
        recommended: this.propertyInfo.isRecommended,
        awards: hasAwardsThisYear ? this.getAwardDescription(this.propertyInfo.hoscars.awards[0]) : false,
        freewifi: this.hasFacility('FREEWIFI', 'FACILITYCATEGORYFREE'),
        breakfastincluded: this.hasFacility('BREAKFASTINCLUDED', 'FACILITYCATEGORYFREE'),
        sustainability:
          this.web_sustainabilityBadges && this.propertyInfo?.sustainability ? this.propertyInfo?.sustainability : null,
      };
    },

    getPropertyHouseRulesData() {
      return {
        policies: this.propertyInfo.houserules.policies,
        checkInStartsAt: this.propertyInfo.houserules.checkin.from,
        checkInEndsAt: this.propertyInfo.houserules.checkin.until,
        cancellationPolicy: this.propertyInfo.houserules.cancellationPolicy,
        latestCheckOut: this.propertyInfo.houserules.latestCheckout,
        thingsToNote: this.propertyInfo.houserules.notes,
      };
    },

    getPropertyReviewsHistoryData() {
      return {
        propertyId: this.propertyInfo.id,
        ratingSummary: this.getPropertyRatingSummaryData,
        ratingBreakdown: this.propertyInfo.rating.breakdown,
      };
    },

    propertyHasAwardsOrNominations() {
      return this.propertyInfo?.hoscars?.awards?.length || this.propertyInfo?.hoscars?.nominations?.length;
    },

    hasFreeCancellation() {
      return this.propertyInfo.policies.freeCancellation.isAvailable;
    },

    getFreeCancellationDay() {
      return this.propertyInfo.policies.freeCancellation.daysInAdvance;
    },

    hasAvailability() {
      return this.propertyInfo?.hasAvailability;
    },

    shouldShowNoAvailabilityMessage() {
      return this.experiment_no_availability_banner && !this.hasAvailability;
    },

    hasSustainability() {
      return this.web_sustainabilityBadges && this.propertyInfo?.sustainability?.tier;
    },

    getSustainabilityIcon() {
      const sustainabilityTierIcon =
        this.propertyInfo?.sustainability?.tier === '4' ? '3-plus' : this.propertyInfo?.sustainability?.tier;
      return {
        asset: `sustainability-${sustainabilityTierIcon}`,
        color: 'green',
        width: 40,
        height: 40,
      };
    },
  },

  mounted() {
    this.trackPageViews();

    this.$root.$on('check-prices', () => {
      this.infoBarItemClicked('prices');
    });

    if (this.$route.query.display === 'reviews') {
      this.openReviewsSideModal();
    }
  },

  methods: {
    ...mapGetters({
      getSearchCheckIn: 'searchForm/getSearchCheckIn',
      getSearchCheckOut: 'searchForm/getSearchCheckOut',
      getSearchPhrase: 'searchForm/getSearchPhrase',
    }),
    ...mapActions({
      setTrackingDetails: 'tracking/setTrackingDetails',
      setPropertyTypeKey: 'tracking/setPropertyTypeKey',
      setPageType: 'tracking/setPageType',
      setProductId: 'tracking/setProductId',
      setPropertyName: 'tracking/setPropertyName',
      setPropertyDepositPercent: 'tracking/setPropertyDepositPercent',
      setPropertyClass: 'tracking/setPropertyClass',
      setPropertyCurrency: 'tracking/setPropertyCurrency',
    }),

    async trackPageViews() {
      this.tracking = await this.$tracking.PropertyPage();

      const propertyDescriptionAutoTranslated = this.propertyInfo.mainDescription.includes(AUTO_GENERATED_TEXT_STRING);

      const searchParamsCheckIn = this.getGtmDates(this.getSearchCheckIn(), 0);
      const searchParamsCheckInStripped = this.getGtmDates(this.getSearchCheckIn(), 1);
      const searchParamsCheckOut = this.getGtmDates(this.getSearchCheckOut(), 0);
      const searchParamsCheckOutStripped = this.getGtmDates(this.getSearchCheckOut(), 1);

      const propertyClass = this.getRatingTranslation(
        this.propertyInfo.rating.score,
        this.propertyInfo.rating.isNew
      ).toLowerCase();
      const propertyDepositPercentage = this.propertyInfo.depositPercentage;
      const propertyType = this.propertyTypeObj.type.toLowerCase();

      const trackingInfo = {
        gtm: {
          gtmPageName: trackingPageType,
          gtmClassification: 'microsite',
          gtmPropertyType: propertyType,
          gtmCanonicalUrl: this.propertyInfo.canonicalUrl,
          gtmContinent: this.propertyInfo.tracking.continent,
          gtmCountry: this.propertyInfo.tracking.country,
          gtmCity: this.propertyInfo.tracking.city,
          gtmPropertyName: this.propertyInfo.name,
          gtmPropertyClass: propertyClass,
          gtmHostelName: this.propertyInfo.name,
          gtmHostelNumber: this.propertyInfo.id,
          gtmDepositServiceFee: propertyDepositPercentage,
          gtmSearchKeywords: this.getSearchPhrase() || '',
          gtmArrivalDate: searchParamsCheckIn,
          gtmArrivalDateStripped: searchParamsCheckInStripped,
          gtmDepartureDate: searchParamsCheckOut,
          gtmDepartureDateStripped: searchParamsCheckOutStripped,
          gtmPropertyIds: this.propertyInfo.id,
          gtmRoomTypes: 'zero',
          gtmPrivateDormSplit: 'zero',
        },
        segment: {
          event_name: 'Property Landing Page Viewed',
          page_type: trackingPageType,
          property_type: propertyType,
          destination_city: this.propertyInfo.tracking.city,
          destination_city_id: this.propertyInfo?.cityId?.toString(),
          destination_country: this.propertyInfo.tracking.country,
          destination_continent: this.propertyInfo.tracking.continent,
          product_id: this.propertyInfo.id?.toString(),
          sku: this.propertyInfo.id?.toString(),
          property_name: this.propertyInfo.tracking.name,
          property_deposit_percent: propertyDepositPercentage || TRACKING_EMPTY,
          property_class: propertyClass || TRACKING_EMPTY,
          property_price_from: this.propertyInfo?.lowestPricePerNight?.value || 0,
          property_currency: this.propertyInfo.currency || TRACKING_EMPTY,
          // @TODO: Add proper values here
          position: TRACKING_EMPTY,
          machine_translation_flag: propertyDescriptionAutoTranslated ? true : undefined,
        },
      };

      // @TODO: Remove after experiment is done
      if (this.web_sustainabilityBadges) {
        trackingInfo.gtm.gtmSustainableLevel = this.propertyInfo?.sustainability?.tier
          ? `SUSTAINABILITY_TIER_${this.propertyInfo.sustainability.tier}`
          : null;
      }

      // @TODO: STATICPWA-901 Remove after experiment is done
      if (this.is_experiment_ppc_redirect_enabled) {
        trackingInfo.segment.spwa_test_id_1 = 'control';
      }

      await this.$store.dispatch('tracking/setTrackingDetails', this.propertyInfo.tracking);
      await this.$store.dispatch('tracking/setPropertyTypeKey', propertyType);
      await this.$store.dispatch('tracking/setPageType', trackingPageType);
      await this.$store.dispatch('tracking/setProductId', this.propertyInfo.id);
      await this.$store.dispatch('tracking/setPropertyName', this.propertyInfo.tracking.name);
      await this.$store.dispatch('tracking/setPropertyDepositPercent', propertyDepositPercentage || TRACKING_EMPTY);
      await this.$store.dispatch('tracking/setPropertyClass', propertyClass || TRACKING_EMPTY);
      await this.$store.dispatch('tracking/setPropertyCurrency', this.propertyInfo.currency || TRACKING_EMPTY);

      await this.tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);

      // @TODO: STATICPWA-901 Remove after experiment is done
      if (this.is_experiment_ppc_redirect_enabled) {
        const trackingInfoPpc = {
          gtm: {
            parameter1: 'control',
            parameter2: trackingPageType,
          },
        };
        await this.tracking?.onPpcRedirectExperiment(trackingInfoPpc.gtm);
      }

      // @TODO: STATICPWA-901 Remove after experiment is done
      await this.tracking.onNoAvailabilityExperiment({
        parameter1: this.shouldShowNoAvailabilityMessage ? 'variation' : 'control',
      });

      if (propertyDescriptionAutoTranslated) {
        await this.tracking.onMachineTranslationEvent({
          parameter1: trackingPageType,
        });
      }
    },

    getGtmDates(date, stripped = 0) {
      const strippedValue = stripped === 1 ? '' : '-';
      return `${date.year}${strippedValue}${String(date.month).padStart(2, '0')}${strippedValue}${String(
        date.day
      ).padStart(2, '0')}`;
    },

    addPropertySchema() {
      const priceRange =
        this.propertyInfo?.propertyPrices?.MinPrice && this.propertyInfo?.propertyPrices?.Currency
          ? `${this.$i18n.t('t_SCHEMAPRICESFROM')} ${this.$currency.format(
              this.propertyInfo.propertyPrices.MinPrice,
              this.propertyInfo.propertyPrices.Currency
            )}`
          : '';

      const mapUrl = `${mapboxConfig.imageApiUrl}${this.propertyInfo?.location?.geoCoordinates?.longitude},${this.propertyInfo?.location?.geoCoordinates?.latitude},14,0/300x300?access_token=${mapboxConfig.microsite.token}`;

      this.$structuredMarkup.addGraph({
        '@type': this.propertyInfo?.schema,
        name: this.propertyInfo.name,
        url: this.seoData.ogUrl,
        description: this.seoData.ogDescription,
        priceRange: priceRange,
        aggregateRating: {
          '@type': 'AggregateRating',
          name: this.propertyInfo.name,
          ratingValue: this.propertyInfo.rating.score,
          reviewCount: this.propertyInfo.rating.totalNumReviews,
          ratingCount: this.propertyInfo.rating.totalNumReviews,
          bestRating: 10,
          worstRating: 0,
        },
        hasMap: mapUrl,
        '@context': 'https://schema.org',
        image: this.seoData.ogImage,
        photo: {
          name: this.propertyInfo.name,
          url: this.seoData.ogImage,
        },
        address: {
          '@type': 'PostalAddress',
          postalCode: '', // @TODO: Add proper value
          addressLocality: this.propertyInfo.city,
          addressRegion: this.propertyInfo.city,
          streetAddress: this.propertyInfo.address,
          addressCountry: this.propertyInfo.country,
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: this.propertyInfo?.location?.geoCoordinates?.latitude,
          longitude: this.propertyInfo?.location?.geoCoordinates?.longitude,
        },
      });
    },

    hasFacility(name, category) {
      if ('filter' in this.propertyInfo.facilities) {
        const checkFacilities = this.propertyInfo.facilities
          .filter((checkFacility) => checkFacility.name === category)
          .flatMap(({ facilities }) => facilities);
        return checkFacilities.filter((checkFacility) => checkFacility.name === name).length;
      }
    },

    getAwardDescription(award) {
      const place = this.$t(`t_HOSCARSPLACE${award.place}`);
      const winner = this.currentYear + ' HOSCARS ' + this.$t(`t_WINNER`).toUpperCase();
      let { key, other } = award;
      key = this.$t('t_' + key);
      // @TODO: refactor tooltip into its own component to accept HTML in a cleaner way
      // eslint-disable-next-line prettier/prettier
      let awardHTML = '<div class="award-details"><p class="year">' + winner + '</p>';
      awardHTML += '<p class="award-name"><span class="award-place">' + place + ' - </span> ' + key + '</p>';
      if (other) {
        awardHTML += '<p class="award-other">' + other + '</p>';
      }
      awardHTML += '</div>';
      return awardHTML;
    },

    openReviewsSideModal() {
      this.modals.reviews = true;
    },

    closeReviewsSideModal() {
      this.modals.reviews = false;
    },

    openImageGalleryModal(image, area) {
      if (this.hasPropertyImages) {
        const trackingInfo = {
          gtm: {
            parameter1: area ? 'header' : 'in-page',
          },
          segment: {
            event_name: 'Property Landing Page Event',
            action: 'Gallery Clicked',
            gallery_panel: area ? 'header' : 'in-page',
            page_type: trackingPageType,
            destination_city: this.propertyInfo?.tracking?.city,
            destination_country: this.propertyInfo?.tracking?.country,
            destination_continent: this.propertyInfo?.tracking?.continent,
            destination_district: this.propertyInfo?.tracking?.district,
            product_id: this.propertyInfo.id,
            sku: this.propertyInfo.id,
            property_type: this.propertyTypeObj.type.toLowerCase(),
            property_name: this.propertyInfo?.tracking?.name,
            property_deposit_percent: this.propertyInfo.depositPercentage || TRACKING_EMPTY,
            property_class:
              this.getRatingTranslation(this.propertyInfo.rating.score, this.propertyInfo.rating.isNew).toLowerCase() ||
              TRACKING_EMPTY,
            property_currency: this.propertyInfo.currency || TRACKING_EMPTY,
          },
        };

        this.tracking?.onImageGalleryClick(trackingInfo.gtm, trackingInfo.segment);

        this.imageGalleryBegin = Number(image) || 0;
        this.modals.imageGallery = true;
      }
    },

    closeImageGalleryModal() {
      this.tracking?.onImageGalleryCloseClick();
      this.modals.imageGallery = false;
    },

    openHouseRules() {
      this.modals.houseRules = true;
    },

    closeHouseRules() {
      this.modals.houseRules = false;
    },

    scrollTo(ref) {
      const element = this.$refs[ref];
      if (element) {
        const top = element.offsetTop;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    },

    infoBarItemClicked(item) {
      switch (item) {
        case 'prices':
          this.scrollTo('property-availability');
          break;
        case 'facilities':
          this.scrollTo('facilities');
          break;
        case 'map':
          this.scrollTo('map');
          break;
        case 'houserules':
          this.openHouseRules();
          break;
        case 'reviews':
          this.openReviewsSideModal();
          break;
        // TODO: More cases to be added here in other tickets
      }
    },

    navigateToCityFab() {
      setHref(
        this.$url.getCityUrl({
          propertyTypeSlug: this.propertyInfo.propertyTypeSlug,
          urlFriendlyContinent: this.propertyInfo.urlFriendlyContinent,
          urlFriendlyCountry: this.propertyInfo.urlFriendlyCountry,
          urlFriendlyCity: this.propertyInfo.urlFriendlyCity,
        })
      );
    },

    handleMorePropertiesCardClick(property) {
      this.tracking?.onMorePropertiesInCityPropertyCardClick({
        parameter1: property.id,
      });
    },

    handleSustainabilityBadgeClick() {
      this.scrollTo('sustainability');

      const trackingInfo = {
        gtm: {
          parameter1: `SUSTAINABILITY_TIER_${this.propertyInfo?.sustainability?.tier}`,
          parameter2: trackingPageType,
        },
      };

      this.tracking?.onSustainabilityIconClick(trackingInfo.gtm, trackingInfo.segment);
    },

    handleSustainabilityLearnMore() {
      const trackingInfo = {
        gtm: {
          parameter1: `SUSTAINABILITY_TIER_${this.propertyInfo?.sustainability?.tier}`,
          parameter2: trackingPageType,
        },
      };

      this.tracking?.onSustainabilityLearnMore(trackingInfo.gtm, trackingInfo.segment);
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: relative;
  min-height: 350px;

  ::v-deep .rwd-hero-image {
    height: 350px;
  }

  .info-area {
    position: absolute;
    bottom: $wds-spacing-m;
    width: 100%;

    ::v-deep .breadcrumbs {
      display: none;

      @media screen and (min-width: $screen-medium-large) {
        display: block;

        &.wrapper {
          padding: $wds-spacing-s $wds-spacing-m;
          margin-left: wds-rem(-4px);
        }

        .breadcrumbs-container {
          padding: $wds-spacing-xs 0;
        }

        li a,
        li span,
        li::after {
          font-size: $wds-font-size-link-2;
          line-height: $wds-line-height-body-3;
          text-transform: capitalize;
          color: $wds-color-white;
        }

        li a:first-child {
          margin-left: $wds-spacing-xs;
        }
      }
    }

    ::v-deep .properties-count {
      display: none;

      @media screen and (min-width: $screen-medium-large) {
        display: initial;
      }
    }
  }

  .hero-clickable-area {
    position: absolute;
    top: 96px;
    width: 100%;
    height: calc(100% - 96px);
    cursor: pointer;

    .property-headline-section {
      display: flex;
      gap: $wds-spacing-s;
      align-items: flex-end;
      height: 100%;
      padding: $wds-spacing-m;

      ::v-deep .property-headline-wrapper {
        flex-grow: 1;
        overflow-x: auto;
      }

      ::v-deep .image-gallery-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $wds-spacing-xs;
        padding: $wds-spacing-xs $wds-spacing-s;
        @include body-3-reg;

        .svg-icon {
          width: $wds-spacing-m;
          height: $wds-spacing-m;
          fill: $wds-color-white;
        }
      }
    }
  }

  @media screen and (min-width: $screen-medium-large) {
    min-height: 430px;

    .hero-clickable-area .property-headline-section ::v-deep .image-gallery-button {
      display: none;
    }
  }
}

.property-no-availability-message {
  padding-top: $wds-spacing-l;
  background-color: #313131;
  color: $wds-color-white;

  > .rwd-wrapper {
    padding: 0 $wds-spacing-m;
  }
}

.check-availability-button {
  padding: $wds-spacing-m;
  background-color: #313131;

  @include desktop {
    display: none;
  }
}

.property-overview-section {
  background: $wds-color-white;
  margin-top: $wds-spacing-m;

  .property-overview-container {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
    padding: $wds-spacing-m;

    .overview-rating {
      display: flex;
      flex-direction: column;
      gap: $wds-spacing-m;

      .rating-old-reviews-message {
        @include body-3-reg;
      }

      .rating-read-all-reviews {
        display: none;
      }
    }
  }

  @include desktop {
    .property-overview-container {
      flex-direction: row;

      .overview-rating {
        flex: 0 0 25%;
        min-height: 200px;

        .rating-read-all-reviews {
          display: flex;
          @include body-2-reg;
        }
      }

      .overview-descriptions {
        flex: 1;
      }
    }
  }
}

.property-image-gallery {
  display: none;

  @include tablet {
    display: block;
    padding: $wds-spacing-m;
  }
}

.property-availability-section {
  margin: $wds-spacing-m 0;
  width: 100%;
  display: block;
  background-color: #f4f4f4;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: $wds-spacing-m;

  .property-availability-container {
    padding: $wds-spacing-m;

    h2 {
      color: $wds-color-ink-dark;
      padding-bottom: $wds-spacing-s;
      @include title-4-bld;
    }

    ::v-deep .search-form-container {
      padding: 0;

      .input-label {
        color: $wds-color-ink;
      }
    }
  }
}

.property-sustainability-section {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'sus-icon sus-title' 'sus-icon sus-description' 'sus-icon sus-more';
  gap: $wds-spacing-s $wds-spacing-l;
  padding: wds-rem(40px) $wds-spacing-m;

  .sustainability-icon {
    grid-area: sus-icon;
  }

  .sustainability-title {
    grid-area: sus-title;
    color: $wds-color-ink-dark;
    @include title-4-bld;
  }

  .sustainability-description {
    grid-area: sus-description;
    @include body-2-reg;
  }

  .sustainability-learn-more {
    grid-area: sus-more;
    @include body-2-reg;
  }
}

.property-map-section {
  margin-bottom: $wds-spacing-m;
  padding: $wds-spacing-m;

  h2 {
    color: $wds-color-ink-dark;
    padding-bottom: $wds-spacing-s;
    @include title-4-bld;
  }

  .property-map-container {
    position: relative;
    height: wds-rem(360px);
    border-radius: $wds-border-radius-m;

    ::v-deep .map-canvas {
      border-radius: $wds-border-radius-m;
    }
  }
}

.property-lastest-reviews-section {
  margin-bottom: $wds-spacing-m;

  & + .property-facilities-section {
    border-top: 1px solid $wds-color-ink-lightest;
  }
}

.property-facilities-container {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;
  padding: $wds-spacing-m;

  h2 {
    color: $wds-color-ink-dark;
    @include title-4-bld;
  }
}

.property-awards-section {
  margin-bottom: $wds-spacing-m;

  .property-awards-container {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
    padding: 0 $wds-spacing-m;

    h2 {
      color: $wds-color-ink-dark;
      @include title-4-bld;
    }
  }
}

.property-says-section {
  margin-bottom: $wds-spacing-m;

  .property-says-container {
    border-top: 1px dotted $wds-color-ink-light;
    padding: $wds-spacing-m;
    @include body-2-reg;

    h2 {
      color: $wds-color-ink-dark;
      padding-bottom: $wds-spacing-s;
      @include title-4-bld;
    }
  }
}

.more-properties-in-city-section {
  border-top: 1px dotted $wds-color-ink-light;
  padding: $wds-spacing-m;
  margin-top: $wds-spacing-xxxl !important;
}

.view-all-city-properties-container {
  padding: $wds-spacing-m;
}
</style>
