<template>
  <div class="search-form-container" :class="{ 'no-location': !this.showLocationField }">
    <div class="location-area" v-if="this.showLocationField">
      <span class="input-label">{{ $t('t_LOCATION') }}</span>
      <div class="field-inner">
        <label class="location-input-label">
          <input
            type="text"
            class="location-input"
            autocomplete="off"
            :placeholder="locationText"
            @focus="handleLocationClick"
          />
          <svg-icon name="search" class="svg-icon location" />
        </label>
        <location
          v-if="displayLocation"
          v-outside.mousedown.focusin="closeLocation"
          :error="showLocationError"
          @close="closeLocation"
          @select="setLocation"
          @geolocationError="handleErrorCode"
        />
      </div>

      <tooltip v-if="showLocationError" :position="tooltipPosition" :force-show="showLocationError">
        <div slot="content">
          {{ $t('t_RWDTOVIEWENTERLOCATION') }}
        </div>
        <span slot="tooltip-toggle" />
      </tooltip>

      <tooltip
        v-if="geolocationPermissionError"
        v-outside.mousedown.focusin="closeGeolocationPermissionError"
        position="bottom-start"
        :force-show="geolocationPermissionError"
        trigger="click"
      >
        <div slot="content">{{ $t('t_CHECKGEOPERMISSIONS') }}</div>
        <span slot="tooltip-toggle" />
      </tooltip>
    </div>

    <div class="checkin-area">
      <span class="input-label">{{ $t('t_RWDCHECKIN') }}</span>
      <div class="field-inner">
        <label>
          <button
            type="button"
            class="checkin-input"
            :title="checkInDate"
            @click="handleCheckInClick"
            @keypress.enter="handleCheckInClick"
          >
            <span>{{ checkInDate }}</span>
            <svg-icon name="calendar" class="svg-icon checkin" />
          </button>
        </label>
        <client-only>
          <date-picker
            v-outside.click.focusin="closeCheckIn"
            v-if="displayCheckIn"
            :date="getSearchCheckIn()"
            :disabled-until="today"
            position="left"
            @datePickerChanged="setCheckInDate"
          />
        </client-only>
      </div>
    </div>

    <div class="checkout-area">
      <span class="input-label">{{ $t('t_RWDCHECKOUT') }}</span>
      <div class="field-inner">
        <label>
          <button
            type="button"
            class="checkout-input"
            :title="checkOutDate"
            @click="handleCheckOutClick"
            @keypress.enter="handleCheckOutClick"
          >
            <span>{{ checkOutDate }}</span>
            <svg-icon name="calendar" class="svg-icon checkout" />
          </button>
        </label>
        <client-only>
          <date-picker
            v-outside.click.focusin="closeCheckOut"
            v-if="displayCheckOut"
            :date="getSearchCheckOut()"
            :disabled-until="getSearchDisabledUntil()"
            :disabled-from="getSearchDisableFrom()"
            :position="isBelowMediumScreen ? 'right' : 'left'"
            @datePickerChanged="setCheckOutDate"
          />
        </client-only>
      </div>
    </div>

    <div class="guests-area">
      <span class="input-label">{{ $t('t_RWDGUESTS') }}</span>
      <div class="field-inner">
        <label>
          <SelectList
            class="search-form-guests"
            :selected="getGuests"
            :options="guestOptions"
            :title="$t('t_RWDGUESTS')"
            @select="setGuests"
          >
            <div role="option" aria-selected="false">
              <div
                :title="getGuests.label"
                class="guests-input"
                @click="handleGuestClick"
                @keypress.enter="handleGuestClick"
                role="button"
                tabindex="0"
              >
                <span>{{ getGuests.label }}</span>
                <svg-icon name="caret" class="svg-icon caret-down" />
              </div>
            </div>
          </SelectList>
        </label>
      </div>
    </div>

    <div class="action-area">
      <button
        class="search-button"
        type="button"
        :title="submitMessageToShow"
        :aria-label="submitMessageToShow"
        @click="submit"
        @keypress.enter="submit"
      >
        {{ submitMessageToShow }}
      </button>
    </div>

    <div v-if="isGroupSearch" class="group-type-area">
      <span class="input-label">{{ $t('t_RWDGROUPTYPE') }}</span>
      <div class="field-inner">
        <label>
          <SelectList
            class="search-form-group-type"
            :selected="getGroupType"
            :options="groupTypeOptions"
            :title="$t('t_RWDGROUPTYPE')"
            @select="setGroupType"
          >
            <div role="option" aria-selected="false">
              <div
                :title="getGroupType.label"
                class="groups-input"
                @click="handleGroupTypeClick"
                @keypress.enter="handleGroupTypeClick"
                role="button"
                tabindex="0"
              >
                <span>{{ getGroupTypeText }}</span>
                <svg-icon name="caret" class="svg-icon caret-down" />
              </div>
            </div>
          </SelectList>
        </label>
      </div>

      <tooltip v-if="showGroupTypeError" :position="tooltipPosition" :force-show="showGroupTypeError">
        <div slot="content">
          {{ $t('t_RWDCHOOSEGROUPTYPE') }}
        </div>
        <span slot="tooltip-toggle" />
      </tooltip>
    </div>

    <div v-if="isGroupSearch" class="group-age-area">
      <span class="input-label">{{ $t('t_RWDAGERANGES') }}</span>
      <div class="field-inner">
        <label>
          <checkbox-button
            v-for="range in groupAgeRangeOptions"
            :key="range.value"
            :label="range.label"
            :value="range"
            :checked="getSearchGroupAge().includes(range)"
            :error="showAgeRangeError"
            @CheckBoxButtonFocus="hideFormFields"
            @buttonChecked="handleGroupAge"
          />
        </label>
      </div>

      <tooltip v-if="showAgeRangeError" :position="tooltipPosition" :force-show="showAgeRangeError">
        <div slot="content">
          {{ $t('t_CHOOSEAGERANGE') }}
        </div>
        <span slot="tooltip-toggle" />
      </tooltip>
    </div>
  </div>
</template>

<script>
import DatePickerMixin from '~/mixins/Search/DatePicker';
import SelectList from '~/components/hwrwd/common/Form/SelectList/SelectList.vue';
import CheckboxButton from '~/components/hwrwd/inputs/CheckboxButton';
import Tooltip from '~/components/hwrwd/core/Tooltip';
import DatePicker from '~/components/hwrwd/common/DatePicker';
import search from '~/config/search';
import groups from '~/config/groups';
import { checkinDate, numberOfNights, leadTime } from '~/lib/timeFromPWA';
import mq from '~/mixins/mq';
import { setHref } from '~/lib/dom';
import { mapActions, mapGetters } from 'vuex';
import { DEFAULT_LANGUAGE } from '~/config/languages';
import { PROPERTY_TYPES, PROPERTY_TYPE_DEFAULT } from '~/config/property-types';
import { TRACKING_EMPTY } from '~/tracking/variables';

export default {
  name: 'SearchForm',

  components: {
    CheckboxButton,
    Location: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/hwrwd/common/SearchForm/components/Location/index'),
    DatePicker,
    Tooltip,
    SelectList,
  },

  mixins: [DatePickerMixin, mq],

  props: {
    location: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    fromAsString: {
      type: String,
      required: false,
      default: null,
    },
    toAsString: {
      type: String,
      required: false,
      default: null,
    },
    guests: {
      type: Number,
      required: false,
      default: search.defaultGuests,
    },
    groupType: {
      type: Number,
      required: false,
      default: null,
    },
    groupAgeRange: {
      type: Array,
      required: false,
      default: () => [],
    },
    submitMessage: {
      type: String,
      required: false,
      default: null,
    },
    showGroupMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLocationField: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      displayLocation: false,
      displayCheckIn: false,
      displayCheckOut: false,
      showSummary: false,
      locationText: '',
      datesText: '',
      onlyShowLocation: false,
      submitMessageToShow: '',
      showLocationError: false,
      geolocationPermissionError: false,
      showGroupTypeError: false,
      showAgeRangeError: false,
      searchData: {
        checkIn: '',
        checkOut: '',
        location: {
          label: this.$t('t_RWDSEARCHBYCITYORHOSTELNAME'),
        },
        guests: {},
        groupType: {},
        groupAge: [],
      },
      tracking: null,
      searchComponentLocation: '',
    };
  },

  created() {
    let actualNumberOfGuests = this.guests || search.defaultGuests;
    let actualNumberOfNights = search.initialNights;
    let actualNumberOfAddDays = search.defaultNights;

    // @TODO: STATICPWA-919 Remove after experiment
    const web_defaultDates = this.$optimizely.isFeatureEnabled('web_defaultDates');
    if (web_defaultDates) {
      actualNumberOfGuests =
        this.$optimizely.getFeatureVariableInteger('web_defaultDates', 'numberofguests') || actualNumberOfGuests;
      actualNumberOfNights =
        this.$optimizely.getFeatureVariableInteger('web_defaultDates', 'numberofnights') || actualNumberOfNights;
      actualNumberOfAddDays =
        this.$optimizely.getFeatureVariableString('web_defaultDates', 'numberofadddays') || actualNumberOfAddDays;

      // If it's set to auto, then we need to figure out if it's Friday, Saturday or Sunday, and switch to Monday
      if (actualNumberOfAddDays === 'auto') {
        const experimentActualDayOfTheWeek = new Date();
        const experimentDayOfTheWeek = experimentActualDayOfTheWeek.getDay();

        actualNumberOfAddDays = 1; // Default day in advance, if not one Friday, Saturday or Sunday
        // Thursday should also be considered on this, since the actual day is Thursday, checkin date will be plus one (Friday)
        if ([0, 4, 5, 6].indexOf(experimentDayOfTheWeek) >= 0) {
          switch (experimentDayOfTheWeek) {
            case 0:
              actualNumberOfAddDays = 1; // Sunday, goes to Monday
              break;
            case 4:
              actualNumberOfAddDays = 4; // Thursday, goes to Monday
              break;
            case 5:
              actualNumberOfAddDays = 3; // Friday, goes to Monday
              break;
            case 6:
              actualNumberOfAddDays = 2; // Saturday, goes to Monday
              break;
          }
        }
      }
    }

    const initialCheckInDate = actualNumberOfAddDays ? checkinDate(null, actualNumberOfAddDays) : checkinDate();

    this.locationText = this.$t('t_RWDSEARCHBYCITYORHOSTELNAME');
    this.submitMessageToShow = this.submitMessage || this.$t('t_RWDSEARCH');

    this.showSummary = this.showHeader;
    this.datesText = `${this.$t('t_RWDCHECKIN')} / ${this.$t('t_RWDCHECKOUT')}`;

    if (this.location.label) {
      this.setLocation(this.location);
    }

    if (this.from && this.to) {
      this.setCheckInDate({ year: this.from.getFullYear(), month: this.from.getMonth() + 1, day: this.from.getDate() });
      this.setCheckOutDate({
        year: this.to.getFullYear(),
        month: this.to.getMonth() + 1,
        day: this.to.getDate(),
      });
    } else {
      const initialCheckIn = initialCheckInDate;
      this.setCheckInDate({
        year: initialCheckIn.getFullYear(),
        month: initialCheckIn.getMonth() + 1,
        day: initialCheckIn.getDate(),
      });
      const initialCheckOut = new Date(
        initialCheckIn.getFullYear(),
        initialCheckIn.getMonth(),
        initialCheckIn.getDate()
      );
      initialCheckOut.setDate(initialCheckOut.getDate() + actualNumberOfNights);
      this.setCheckOutDate({
        year: initialCheckOut.getFullYear(),
        month: initialCheckOut.getMonth() + 1,
        day: initialCheckOut.getDate(),
      });
    }

    this.setSearchNumberOfGuests(this.guestOptions[actualNumberOfGuests - 1]);

    if (this.groupType) {
      this.setGroupType(this.groupTypeOptions.find((e) => e.value === this.groupType));
    }

    this.setSearchGroupAge(this.groupAgeRange.map((value) => groups.ageRanges.find((e) => e.value === value)));

    this.searchComponentLocation = this.showLocationField ? 'search-form' : 'check-availability';

    this.$root.$on('group-search-triggered', (groupGuestCount) => {
      this.showSummary = false;
      this.setSearchNumberOfGuests(this.guestOptions[groupGuestCount - 1]);
      this.$root.$emit('check-prices');
    });
  },

  mounted() {
    this.getTracking();
  },

  methods: {
    ...mapGetters({
      getSearchNumberOfGuests: 'searchForm/getSearchNumberOfGuests',
      getSearchGroupType: 'searchForm/getSearchGroupType',
      getSearchGroupAge: 'searchForm/getSearchGroupAge',
      getIsSearchActivated: 'searchForm/getIsSearchActivated',
      getCurrentLanguage: 'session/getCurrentLanguage',
      getCurrentCurrency: 'session/getCurrentCurrency',
    }),
    ...mapActions({
      setSearchNumberOfGuests: 'searchForm/setSearchNumberOfGuests',
      setSearchGroupType: 'searchForm/setSearchGroupType',
      setSearchGroupAge: 'searchForm/setSearchGroupAge',
      addSearchGroupAge: 'searchForm/addSearchGroupAge',
      setSearchActivated: 'searchForm/setSearchActivated',
      setSearchPhrase: 'searchForm/setSearchPhrase',
    }),

    async getTracking() {
      this.tracking = await this.$tracking.SearchFormComponent();
    },

    closeLocation() {
      this.displayLocation = false;
    },
    closeGeolocationPermissionError() {
      this.geolocationPermissionError = false;
    },

    handleLocationClick() {
      this.hideFormFields();
      this.showLocationError = false;
      this.closeGeolocationPermissionError();
      this.displayLocation = true;
      if (!this.getIsSearchActivated()) {
        this.tracking?.onSearchActivated('destination', this.searchComponentLocation);
        this.setSearchActivated(true);
      }
      this.$emit('click-location');
    },

    handleCheckInClick() {
      if (!this.getIsSearchActivated()) {
        this.tracking?.onSearchActivated('check-in date', this.searchComponentLocation);
        this.setSearchActivated(true);
      }
      this.toggleCheckIn();
    },

    handleCheckOutClick() {
      if (!this.getIsSearchActivated()) {
        this.tracking?.onSearchActivated('check-out date', this.searchComponentLocation);
        this.setSearchActivated(true);
      }
      this.toggleCheckOut();
    },

    handleGuestClick() {
      if (!this.getIsSearchActivated()) {
        this.tracking?.onSearchActivated('guests', this.searchComponentLocation);
        this.setSearchActivated(true);
      }
    },

    handleGroupTypeClick() {
      this.showGroupTypeError = false;
    },

    handleGroupAge($event) {
      this.hideFormFields();
      this.showAgeRangeError = false;

      const currentGroupAge = this.getSearchGroupAge();
      if (currentGroupAge.includes($event.value)) {
        if ($event.checked) {
          return;
        }

        this.setSearchGroupAge(currentGroupAge.filter((item) => item !== $event.value));
        return;
      }

      this.addSearchGroupAge($event.value);
    },

    hideFormFields() {
      this.displayLocation = false;
      this.displayCheckIn = false;
      this.displayCheckOut = false;
    },

    setLocation($event) {
      this.locationText = $event.label;
      this.searchData.location = $event;
      this.setSearchPhrase($event.label);
    },

    setGuests(value) {
      this.setSearchNumberOfGuests(value);
    },

    setGroupType(event) {
      this.setSearchGroupType(event);
    },

    showField(field) {
      this.hideFormFields();
      this[field] = true;
    },

    handleErrorCode() {
      this.geolocationPermissionError = true;
    },

    async submit() {
      let redirectUrl = null;

      let trackingData = {};

      const groupType = this.getSearchGroupType();
      const groupAges = this.getSearchGroupAge();
      const langUrl = this.getCurrentLanguage.tag || DEFAULT_LANGUAGE.tag;
      const userCurrency = this.getCurrentCurrency().code;

      const propertyTypeObj =
        PROPERTY_TYPES.find((pt) => this.$t(pt.slug) === this.$store.state.route.data.propertyType) ||
        PROPERTY_TYPE_DEFAULT;

      this.showLocationError = this.searchData.location.label === this.$t('t_RWDSEARCHBYCITYORHOSTELNAME');
      this.showGroupTypeError = this.isGroupSearch && !groupType.value;
      this.showAgeRangeError = this.isGroupSearch && groupAges.length === 0;

      if (this.showLocationError || this.showGroupTypeError || this.showAgeRangeError) {
        return;
      }

      let extraParams = {
        from: this.getCheckInDate(),
        to: this.getCheckOutDate(),
        guests: this.getSearchNumberOfGuests().guests,
      };

      const dateLeadTime = leadTime(extraParams.from);

      trackingData.arrival_date = extraParams.from;
      trackingData.departure_date = extraParams.to;
      trackingData.number_nights = numberOfNights(extraParams.from, extraParams.to);
      trackingData.number_guests = extraParams.guests;
      trackingData.search_keywords =
        this.searchData?.location?.label?.toLowerCase()?.replace(/[\W_]+/g, '-') || TRACKING_EMPTY;
      trackingData.property_type = this.$store.state.route.data.propertyType;
      trackingData.lead_time = dateLeadTime > 0 ? dateLeadTime : 'zero';
      trackingData.category = propertyTypeObj.key;

      switch (this.searchData.location.type) {
        case 'continent':
          const continentData = {
            propertyTypeSlug: this.$store.state.route.data.propertyType,
            urlFriendlyContinent: this.searchData.location.label.toLowerCase().replace(/[\W_]+/g, '-'),
          };
          trackingData.destination_continent = continentData?.urlFriendlyContinent;

          redirectUrl = this.$url.getContinentPageUrl(continentData, {});
          break;
        case 'country':
          const countriesServices = await this.$api.getSpApi().getServices('countries');
          const countryResponse = await countriesServices.getCountryById(
            langUrl,
            userCurrency,
            propertyTypeObj.key,
            this.searchData.location.id
          );

          const countryData = {
            propertyTypeSlug: this.$store.state.route.data.propertyType,
            urlFriendlyContinent: countryResponse.urlFriendlyContinent,
            urlFriendlyCountry: this.searchData.location.label.toLowerCase().replace(/[\W_]+/g, '-'),
          };

          trackingData.destination_country = countryData?.urlFriendlyCountry;
          trackingData.destination_continent = countryData?.urlFriendlyContinent;

          redirectUrl = this.$url.getCountryPageUrl(countryData, {});
          break;
        case 'state':
          const areasServices = await this.$api.getSpApi().getServices('areas');
          const areasResponse = await areasServices.getAreaById(
            langUrl,
            userCurrency,
            propertyTypeObj.key,
            this.searchData.location.id
          );

          const areaCountryData = {
            propertyTypeSlug: this.$store.state.route.data.propertyType,
            urlFriendlyContinent: areasResponse.urlFriendlyContinent,
            urlFriendlyCountry: areasResponse.urlFriendlyCountry,
          };

          const areaData = {
            urlFriendlyName: areasResponse.urlFriendlyName,
          };

          trackingData.destination_area = areaData?.urlFriendlyName;
          trackingData.destination_country = areaCountryData?.urlFriendlyCountry;
          trackingData.destination_continent = areaCountryData?.urlFriendlyContinent;

          redirectUrl = this.$url.getAreasUrl(areaCountryData, areaData, null);
          break;
        case 'region':
          const regionsServices = await this.$api.getSpApi().getServices('regions');
          const regionResponse = await regionsServices.getRegionById(
            langUrl,
            userCurrency,
            propertyTypeObj.key,
            this.searchData.location.id
          );

          const regionCountryData = {
            propertyTypeSlug: this.$store.state.route.data.propertyType,
            urlFriendlyContinent: regionResponse.urlFriendlyContinent,
            urlFriendlyCountry: regionResponse.urlFriendlyCountry,
          };

          const regionData = {
            urlFriendlyName: regionResponse.urlFriendlyName,
          };

          trackingData.destination_region = regionData?.urlFriendlyName;
          trackingData.destination_country = regionCountryData?.urlFriendlyCountry;
          trackingData.destination_continent = regionCountryData?.urlFriendlyContinent;

          redirectUrl = this.$url.getRegionsUrl(regionCountryData, regionData, null);
          break;
        case 'city':
          const cityLocationParts = this.searchData.location.label.replace(/ /g, '').split(',');
          const cityLocationData = {
            id: this.searchData.location.id,
            urlFriendlyName: cityLocationParts[0],
            urlFriendlyCountry: cityLocationParts[cityLocationParts.length - 1],
          };

          trackingData.destination_city = cityLocationData?.urlFriendlyName;
          trackingData.destination_country = cityLocationData?.urlFriendlyCountry;
          trackingData.auto_sort_order_applied = 'sfab';
          trackingData.page_sort_order_applied = 'sfab';
          trackingData.listing_type = 'list';

          redirectUrl = this.$url.getCityDynamicFabUrl(cityLocationData, extraParams);
          break;
        case 'citydistricts':
          extraParams = {
            ...extraParams,
            ...{ district: this.searchData.location.id },
          };

          const cityDistrictParts = this.searchData.location.city.replace(/ /g, '').split(',');
          const cityDistrictItems = this.searchData.location.label.replace(/ /g, '').split(',');
          const cityDistrictData = {
            id: this.searchData.location.cityId,
            urlFriendlyName: cityDistrictParts[0],
            urlFriendlyCountry: cityDistrictParts[cityDistrictParts.length - 1],
          };

          trackingData.destination_district = cityDistrictItems[0];
          trackingData.destination_city = cityDistrictData?.urlFriendlyName;
          trackingData.destination_country = cityDistrictData?.urlFriendlyCountry;
          trackingData.auto_sort_order_applied = 'sfab';
          trackingData.page_sort_order_applied = 'sfab';
          trackingData.listing_type = 'list';

          redirectUrl = this.$url.getCityDynamicFabUrl(cityDistrictData, extraParams);
          break;
        case 'property':
          const propertyLocationData = {
            urlFriendlyName: this.searchData.location.city.replace(/[\W_]+/g, '-'),
          };
          const propertyData = {
            id: this.searchData.location.id,
            urlFriendlyName: this.searchData.location.hostelName.replace(/[\W_]+/g, '-'),
          };

          trackingData.property_name = propertyData?.urlFriendlyName;
          trackingData.destination_city = propertyLocationData?.urlFriendlyName;
          trackingData.product_id = propertyData?.id?.toString();
          trackingData.sku = propertyData?.id?.toString();

          redirectUrl = this.$url.getPwaPropertyDynamicUrl(propertyLocationData, propertyData, extraParams);
          break;
      }

      if (!redirectUrl) {
        return;
      }

      if (this.isGroupSearch) {
        if (groupType.value && groupType.code) {
          redirectUrl += `&groupType=${groupType.value}`;
          trackingData.group_type = groupType.code;
        }

        if (groupAges.length > 0) {
          trackingData.group_age_range = groupAges
            .flatMap(({ label }) => {
              return label;
            })
            .join(',');

          groupAges.forEach((groupAge) => {
            redirectUrl += `&groupAgeRange=${groupAge.value}`;
          });
        }
      }

      // We need to add this parameter only on the property page - STATICPWA-896
      if (this?.$route?.name === 'propertyType-p-propertyId-propertyName') {
        redirectUrl += `&origin=microsite`;
      }

      this.tracking?.onSearchSubmitted(trackingData);

      setTimeout(() => {
        setHref(redirectUrl);
      }, 100);
    },
  },

  computed: {
    to() {
      return this.toAsString ? parse(this.toAsString, 'yyyy-MM-dd', new Date(Date.now())) : null;
    },
    from() {
      return this.fromAsString ? parse(this.fromAsString, 'yyyy-MM-dd', new Date(Date.now())) : null;
    },
    getGuests() {
      return this.getSearchNumberOfGuests();
    },
    getGroupType() {
      return this.getSearchGroupType();
    },
    getGroupTypeText() {
      return this.getGroupType?.label || this.$t('t_RWDGROUPTYPE');
    },
    tooltipPosition() {
      return this.$device.isDesktop ? 'bottom-start' : 'bottom-end';
    },
    formLayout() {
      return this.layout || this.defaultLayout;
    },
    defaultLayout() {
      if (this.$device.isMobile) {
        return 1;
      } else if (this.$device.isDesktop) {
        return 2;
      }
      return 3;
    },
    showHeader() {
      return [4, 5, 6].includes(this.formLayout);
    },
    guestOptions() {
      const singularStr = this.$t('t_RWDGUEST');
      const pluralStr = this.$t('t_RWDGUESTS');
      return Array.from({ length: search.maxGuests }, (i, k) => {
        const guests = k + 1;
        const label = `${guests} ${guests === 1 ? singularStr : pluralStr}`;
        return { guests, label };
      });
    },
    groupTypeOptions() {
      return groups.groupTypes.map((e) => {
        e.label = this.$t(e.label);
        return e;
      });
    },
    groupAgeRangeOptions() {
      return groups.ageRanges;
    },
    isGroupSearch() {
      return this.getSearchNumberOfGuests().guests >= groups.minGuests;
    },
    isLargeGroupSearch() {
      return this.getSearchNumberOfGuests().guests >= groups.minGuestsEnquire;
    },
    checkoutDatepickerPosition() {
      const dims = this.$refs.checkOutField.getBoundingClientRect();
      if (this.$device.isMobile || dims.right > window.innerWidth - dims.width) {
        return 'right';
      } else {
        return 'left';
      }
    },
  },

  watch: {
    location(location) {
      this.setLocation(location);
    },
    from(from) {
      this.setCheckInDate({ year: from.getFullYear(), month: from.getMonth() + 1, day: from.getDate() });
    },
    to(to) {
      this.setCheckOutDate({ year: to.getFullYear(), month: to.getMonth() + 1, day: to.getDate() });
    },
    guests(guests) {
      this.setSearchNumberOfGuests(this.guestOptions[guests - 1]);
    },
    groupType(groupType) {
      this.setGroupType(this.groupTypeOptions.find((e) => e.value === groupType) || {});
    },
    groupAgeRange(groupAgeRange) {
      this.setSearchGroupAge(groupAgeRange.map((value) => groups.ageRanges.find((e) => e.value === value)));
    },
    checkIn(newValue) {
      this.searchData.checkIn = newValue;
    },
    checkOut(newValue) {
      this.searchData.checkOut = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-form-container {
  margin: 0 auto;
  padding: $wds-spacing-m 0.75rem;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 47% 47%;
  grid-gap: $wds-spacing-s $wds-spacing-m;
  grid-template-areas:
    'location-area location-area'
    'checkin-area checkout-area'
    'guests-area action-area'
    'group-type-area group-type-area'
    'group-age-area group-age-area';

  @media screen and (min-width: $screen-large) {
    grid-template-columns: 33% 15% 15% 15% 15%;
    grid-template-areas:
      'location-area checkin-area checkout-area guests-area action-area'
      'group-type-area group-type-area group-age-area group-age-area group-age-area';
  }

  &.no-location {
    grid-template-areas:
      'checkin-area checkout-area'
      'guests-area action-area'
      'group-type-area group-type-area'
      'group-age-area group-age-area';

    @include desktop {
      justify-content: flex-start;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'checkin-area checkout-area guests-area action-area'
        'group-type-area group-age-area group-age-area group-age-area';
    }
  }

  .input-label {
    color: #ccc;
    margin-bottom: wds-rem(2px);
    display: block;
    text-transform: uppercase;
    font-size: wds-rem(11px);
    font-weight: 700;
  }

  .field-inner {
    label {
      display: flex;
      align-items: center;
      position: relative;
    }
  }

  .search-button {
    display: block;
    width: 100%;
    font-weight: 700;
    background: $wds-color-orange;
    color: $wds-color-white;
    font-size: $wds-font-size-body-1;
    letter-spacing: 0;
    min-height: wds-rem(42px);
    user-select: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: wds-rem(4px);
    outline: none;
    border: none;
    line-height: normal;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $wds-color-orange-dark;
      color: $wds-color-white;
    }

    &:focus {
      box-shadow: 0 0 0 $wds-spacing-xxs $wds-color-white, 0 0 0 $wds-spacing-xs $wds-color-ink-darker;
    }
  }

  .search-form-guests,
  .search-form-group-type {
    flex-grow: 1;
  }

  .svg-icon {
    width: $wds-spacing-m;
    height: $wds-spacing-m;
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);

    &.location,
    &.checkin,
    &.checkout,
    &.guests,
    &.caret-down {
      position: absolute;
      fill: #ccc;
      right: $wds-spacing-s;
    }

    &.guests {
      right: wds-rem(32px);
    }
  }

  .location-input,
  .checkin-input,
  .checkout-input,
  .guests-input,
  .groups-input {
    display: flex;
    align-items: center;
    border: 0;
    font-size: 0.8rem;
    color: #666;
    font-weight: normal;
    width: 100%;
    min-height: wds-rem(42px);
    padding: 0 wds-rem(30px) 0 $wds-spacing-s;
    border-radius: wds-rem(3px);
    cursor: pointer;
    background: $wds-color-white;
  }

  .guests-input {
    padding: 0 wds-rem(58px) 0 $wds-spacing-s;
    word-break: break-word;
  }

  .location-input-label:hover,
  .checkin-input:hover,
  .checkout-input:hover,
  .guests-input:hover,
  .groups-input:hover {
    .svg-icon {
      fill: #666;
    }
  }

  .location-area {
    grid-area: location-area;

    .field-inner {
      position: relative;
    }
  }

  .checkin-area {
    position: relative;
    grid-area: checkin-area;
  }

  .checkout-area {
    position: relative;
    grid-area: checkout-area;
  }

  .guests-area {
    grid-area: guests-area;
  }

  .action-area {
    grid-area: action-area;
    margin-top: wds-rem(24px);
  }

  .group-type-area {
    grid-area: group-type-area;
  }

  .group-age-area {
    grid-area: group-age-area;

    label {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 0.5rem;
    }
  }

  ::v-deep .select-list .menu {
    border-radius: 0;
  }

  ::v-deep .popper {
    color: #9d0000;
    background: #ffe5e5;
    border: 1px solid #ffb8b8;
  }
}
</style>
